let faqsQ = document.getElementsByClassName('question');
let faqsA = document.getElementsByClassName('answer');

for (let i = 0; i < faqsQ.length; i++) {
    faqsQ[i].addEventListener('click', function() {
        if (faqsQ[i].classList.contains('open')) {
            faqsQ[i].classList.remove('open');
            faqsA[i].classList.remove('open');
            faqsA[i].style.maxHeight = null;
        } else {
            faqsQ[i].classList.add('open');
            faqsA[i].classList.add('open');
            faqsA[i].style.maxHeight = faqsA[i].scrollHeight + 'px';
        }
    })
}